import React, { Fragment, useContext, useEffect, useState, useRef } from 'react'
import { Link } from "gatsby";
import styled from "styled-components";
import breakpoints from "../../utils/breakpoints";
import { grid } from "../../utils";
import { useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser";

import Logolight from "../../assets/vectors/logo-latam-light.svg";
import External from "../../assets/vectors/external.svg";

const Container = styled.footer`
  background-color: var(--brand-gray);
  color: #fff;
  padding: 4rem calc(var(--grid-padding) * 0.75) 4rem;

  @media ${breakpoints.tabletSm} {
    padding: calc(var(--grid-padding) * 2) calc(var(--grid-padding)) calc(var(--grid-padding));
  }

  @media ${breakpoints.tablet} {
    padding: calc(var(--grid-padding) * 2) calc(var(--grid-padding) * 1) calc(var(--grid-padding) * 1.25);
  }

  @media ${breakpoints.desktop} {
    padding: calc(var(--grid-padding) * 2) calc(var(--grid-padding) * 1.83) calc(var(--grid-padding) * 1.5);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    @media ${breakpoints.tabletSm} {
      width: ${grid(3)};
    }

    @media ${breakpoints.tablet} {
      position: relative;
      width: ${grid(2.68)};
    }

    li {
      white-space: nowrap;
    }

    a {
      display: flex;
      align-items: flex-start;
      font-family: var(--font-secondary);
      font-size: 1.8rem;
      line-height: 3.4rem;
      font-weight: 200;

      @media ${breakpoints.tablet} {
        font-size: 2.2rem;
        line-height: 3.6rem;
        font-weight: 300;
      }

      @media ${breakpoints.desktop} {
        font-size: 2.6rem;
        line-height: 4rem;
      }

      &:hover {
        text-decoration: underline;
        color: #fff;
      }
    }

    svg {
      display: block;
      width: 0.6rem;
      height: 0.6rem;
      stroke: currentColor;
      position: relative;
      top: 0.3rem;
      left: 0.4rem;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding: 0;
  
  @media ${breakpoints.tabletSm} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 3rem;
  }
  @media ${breakpoints.tablet} {
    padding-bottom: 0;
  }
`;

const Leftcol = styled.div`
  display: block;
  width: 14rem;
  margin-left:0;

  @media ${breakpoints.tabletSm} {
    position: relative;
    margin-bottom: 0;
    width: 30rem;
  }
  @media ${breakpoints.desktop} {
    left: 0;
  }
`;

const Copy = styled.div`
  position: relative;
  font-family: var(--font-primary);
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 100;
`

const Rightcol = styled.div`
  position: relative;
  padding: 0 0 0;
`;

const MenuWrapper = styled.div`
  position: relative;
  padding: 0 0 0;
`;

const Logo = styled(Link)`
  display: block;
  fill: #fff;
  width: 14rem;
  height: auto;
  margin-bottom: 6rem;

  @media ${breakpoints.tabletSm} {
    position: relative;
    margin-bottom: 6rem;
    width: 20rem;
  }

  @media ${breakpoints.desktop} {
    left: 0;
  }

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const Copyright = styled.div`
  position: relative;
  padding-top: 4.7rem;
  font-family: var(--font-primary);
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 100;
  font-style: italic;

  @media ${breakpoints.tabletSm} {
    padding-left: 0;
    padding-top: 15rem;
  }

  @media ${breakpoints.desktop} {
    padding-left: 0;
    padding-top: 22.5rem;
  }

  p {
    display: block;
    padding-left: 0;
    margin-bottom: 0.4rem;

    @media ${breakpoints.tablet} {
      padding-left: 1rem;
    }
  }

  span {
    display: inline-block;
  }

  ul {
    display: inline-flex;
    top: 0rem;
    width: auto;

    li {
      a,
      button {
        font-family: var(--font-primary);
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 300;
        font-style: normal;
        text-decoration: underline;
      }

      button {
        appearance: none;
      }
    }

    .break {
      display: inline-block;
      padding: 0 0.75rem;
      font-family: var(--font-primary);
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 300;
    }
  }
`;

export default function Footer({
  footer_nav,
  global
}) {
  const data = useStaticQuery(graphql`
    query footer {
      cms {
        globalSet(handle: "footer") {
          ... on cms_GlobalSet_Footer {
            copyright
            links {
              ... on cms_Set_Links_Links {
                link_link
                link_label
                link_external
                link_btn_type {
                  label
                  value
                }
              }
            }
          }
        },
        nav(handle: "footer_nav") {
          tree {
            page {
              title
              url
            }
            children {
              page {
                title
                url
              }
            }
          }
        }
      }
    }
  `)
  const footernav = footer_nav?.tree || data.cms.nav.tree;
  const copyright_nav = global?.links || data.cms.globalSet.links;
  const copyright = global?.copyright || data.cms.globalSet?.copyright;

  const renewCookies = () => {
    try {
      window?.Cookiebot?.renew();
    } catch (error) {
      console.error('Cookiebot error', error);
    }
  }

  return (
    <Container>
      <Wrapper>
        <Leftcol>
          <Logo to="/">
            <Logolight />
          </Logo>
          <Copy>
            <p>Miami<br />
              Brickell City Center – 78 SW 7th St, 9th floor<br />
              Miami, FL 33130</p>

            <p>Mexico City <br />
              Calle Montes Urales 424, Lomas-Virreyes<br />
              Lomas de Chapultepec, Miguel Hidalgo, 11000</p>

            <p>São Paulo <br />
              Avenida Rebouças, 2728, 15th floor<br />
              CEP 05401-400 – S. Paulo - SP</p>
          </Copy>
        </Leftcol>
        <Rightcol>
          <MenuWrapper>
            <ul>
              {footernav.map((link, i) => {
                return (
                  <li key={i}>
                    {!link.page.external && <Link to={link.page.url}>{link.page.title}</Link>}

                    {link.page.external && (
                      <a href={link.page.url} target="_blank" rel="noreferrer">
                        <span>{link.page.title}</span>
                        <span>
                          <External />
                        </span>
                      </a>
                    )}
                  </li>
                );
              })}
            </ul>
            <Copyright>
              <div>
                {copyright && parse(copyright)}
                <ul>
                  {copyright_nav.map((link, i, copyright_nav) => {
                    return (
                      <Fragment key={i}>
                        <li>
                          {!link.link_external && <Link to={link.link_link}>{link.link_label}</Link>}

                          {link.link_external && (
                            <a href={link.link_link} target="_blank" rel="noreferrer">
                              <span>{link.link_label}</span>
                              <span>
                                <External />
                              </span>
                            </a>
                          )}
                        </li>

                        {i < copyright_nav.length && (
                          <div className="break">|</div>
                        )}
                      </Fragment>
                    );
                  })}
                  <li>
                    <button type='button' onClick={renewCookies}>
                      Cookie Preferences
                    </button>
                  </li>
                </ul>
              </div>

            </Copyright>
          </MenuWrapper>
        </Rightcol>
      </Wrapper>
    </Container>
  );
}
